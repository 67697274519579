<template>
  <div>

    <br />
    <!-- <NotResults v-if="error == 204" code="204" /> -->
    <div  class="container">
      <div class="mb-5">
        <template>
          <div class="row">
          <!-- <div
            class="col-md-4"
            v-for="(item, i) in results"
            :key="item.folio"
            :name="item.folio"
            :index="i"
          > -->
          <div
          class=
          col-lg-4
          col-md-12
          pt-4
          mt-2>
            <!-- <PropertyHorCard :item="item" /> -->
            <SearchForm/>
          </div>
          <div
          class=
          col-lg-8
          col-md-12
          >
            <!-- <PropertyHorCard :item="item" /> -->
            <PropertyHorCard
            v-for="(item, i) in results"
            :key="item.folio"
            :name="item.folio"
            :index="i" :item="item"/>
          </div>
        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import Preloader from "@/components/layout/Preloader.vue";
import PropertyHorCard from "@/components/properties/PropertyHorCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
  components: {
    SearchForm,
    PropertyHorCard,
    Preloader,
    NotResults,
  },
  data() {
    return {
      codeShareProperties: 0,
      revRoute: null,
    };
  },
  computed: {
    ...mapGetters({
      results: "_getResults",
      error: "_getError",
   }), 
   }

  // beforeRouteEnter(to, from, next) {
  //   next((self) => {
  //     self.prevRoute = from;
  //     if (!self.prevRoute.name) {
  //       self.$router.push({ name: "Home" });
  //     }
  //   });
  // },
};
</script>

<style scoped>
  
</style>