<template>
    <header>
        <nav
        class="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light sleep"
        id="ftco-navbar"
        >
        <div class="container">
            <div class="col-8 col-md-8 col-lg-4">
              <router-link to="/">
                  <!-- <img
                  v-if="info"
                  class="header-logo m-1"
                  :src="info.logoClean"
                  alt="logo_web"
                  />
              <h4 v-else>{{ info.companyName }}</h4> -->
              <img class="logo mt-3 m-0" src="../../../public/clasificados-logo-blanco.png" />
              </router-link>
            </div>
            <button
            class="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            >
            <span class="oi oi-menu"></span>
            </button>
            <div class="collapse navbar-collapse" id="ftco-nav">
            <ul class="navbar-nav ml-auto header">
                <li class="nav-item cta">
                    <router-link to="/" class="nav-link">
                        <div class="icon-top"> 
                            <span>Inicio</span>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item cta">
                    <router-link to="/registro" class="nav-link ">
                        <div class="icon-top">
                            <i class="fas fa-user-plus"></i>
                            <span>Registrate</span>
                        </div>
                    </router-link>
                </li>
                <li class="nav-item cta">
                    <router-link to="/contacto" class="nav-link ">
                        <div class="icon-top">
                            <i class="fas fa-phone"></i>
                            <span>Contacto</span>
                        </div>
                    </router-link>
                </li>
            </ul>
            </div>
        </div>
        </nav>
    </header>
</template>


<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components:{
    LocaleSwitcher
  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
}
</script>

<style scoped>
.header-logo {
  width: 15vw !important;
}
@media only screen and (max-width: 991px) {
  .header-logo {
    width: 20%;
  }
}
.header{
    text-transform: uppercase;
}

.icon-top{
    background-color:#00569f;
    font-size:15px;
    border-radius: .5rem;
    padding: 10px;
    border-width: .25rem;
    /* border-color: rgba(0,0,0,0.2);
    border-bottom-style: solid;
    border-right-style: solid; */
}

.icon-top:hover{
    /* border-color: rgba(169, 163, 5, 0.6);
    border-bottom-style: solid;
    border-right-style: solid; */
    color: #f2e115 ;
}

.icon-top span{
    padding-left:5px;
    font-family: 'Poppins' !important;
    font-style: 'Extra-light';
    font-size: 15px;
}
</style>