<template lang="html">
  <div id="app">
    <Header/>
    <Banner v-if="!$route.meta.hideBanner" :pageTitle="$route.meta.pageTitle"/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/layout/Header.vue";
import Banner from "@/components/layout/Banner.vue";
import Footer from "@/components/layout/Footer.vue";

export default{
  name: "App",
  components:{
    Header,
    Banner,
    Footer
  }
}
</script>


<style lang="scss">
app{
  background-color: #ebebeb
}



</style>
