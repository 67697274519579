<template>
  <div>
    <section class="ftco-section login-section pb-5">
      <div class="container cont">
        <div class="row">
        <div class="col-md-12 mb-3 text-center">
            <div class="registration-form text-center">
              <form
                id="login"
                v-on:submit.prevent="loginUser();"
                method="post" >
                <div class="mb-2 mt-2 text-center">
                  <router-link to="/">
                    <img src="../../../public/clasificadoslogo.png" />
                  </router-link>
                </div>
                <div class="form-group mt-4 mb-2">
                    <input type="text" v-model="userLogin.RDx_correo" :state="false" class="form-control item pl-4 mb-3 mt-3" @blur="$v.userLogin.RDx_correo.$touch()" placeholder="Correo electrónico">
                     <small><span v-if="(!$v.userLogin.RDx_correo.required || !$v.userLogin.RDx_correo.email) && $v.userLogin.RDx_correo.$dirty" class="text-danger">Correo eletrónico inválido </span></small>
                </div>
                <div class="form-group mt-4 mb-2">
                    <input type="password" v-model="userLogin.RDx_contra" class="form-control item pl-4 mb-3 mt-3"  @blur="$v.userLogin.RDx_contra.$touch()" placeholder="Contraseña">
                <small><span v-if="!$v.userLogin.RDx_contra.required && $v.userLogin.RDx_contra.$dirty" class="text-danger">Contraseña es requerida </span></small>
                </div>
                <div class="form-group mt-4 mb-4">
                    <button type="button" class="form-control btn btn-primary font-weight-bold" @click="loginUser()" style="font-family:'Poppins;">INICIAR SESIÓN</button>
                </div>
                <a href="/registro"><span>¿Olvidaste tu contraseña?</span></a> <br>
                <a href="/registro">¿No tienes cuenta?, <span>¡Regístrate!</span></a>
              </form>
         </div>
        </div>
      </div>
      </div>
    </section>
  </div>
    
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
export default {
  name: "app",
  components: {
    //Banner,
    // SearchForm,
    // ContactForm,
  },
  data() {
    return {
      contact: true,
      userLogin: {
        RDx_correo: '',
        RDx_contra: '',
      }
    };
    
  },
    validations:{
    userLogin:{
      RDx_correo: {
          required,
          email
      },
      RDx_contra: {
          required,
      },
    }
  },
    methods: {
    loginUser(){
      this.sendData = true;
      var validate = false;
      if(!this.$v.$invalid){  
        // alert("entrando");
        validate = true;
      } else{
        this.$v.$touch();
      }
      this.userLogin.RDx_contra = window.btoa(unescape(encodeURIComponent( this.userLogin.RDx_contra )));
      validate = true;
      if(validate){
        this.$store.dispatch("userLogin", this.userLogin);
      }

    }
  }
  // computed: {
  //   ...mapGetters({
  //     info: "_getInfoCompany",
  //   }),
  // },
};
</script>

<style >
.login-section{
  background-color: #f8f8f8 !important;
  padding: 4.5rem, 0 !important;
}
.login-section span{
  color: #00569d;
}
.login-section a:hover{
  color: inherit;
}
.login-section img{
  width: 100% !important;
}
.registration-form{
	padding: 30px 0;
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
@media (max-width: 576px) {
    .registration-form form{
        padding: 50px 20px;
    }
}
.registration-form .item{
	border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border: inherit;
}
.login-section .cont{
  max-width: 600px !important;
  background: #fff;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 20px;
}


</style>
