var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"p-1 contact-form mt-0 mb-5 justify-content-center",attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.sendMail.apply(null, arguments)}}},[_c('div',{staticClass:"row form-group"},[_c('div',{class:[
          _vm.nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12', 'mb-3'
        ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.name.value),expression:"contactForm.name.value"}],staticClass:"form-control ",attrs:{"type":"text","id":"fullname","name":"name","required":"required","placeholder":"Nombre *","disabled":_vm.sent || _vm.sending},domProps:{"value":(_vm.contactForm.name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactForm.name, "value", $event.target.value)}}}),(!_vm.contactForm.name.status)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido. ")]):_vm._e()]),_c('div',{class:[
          _vm.nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12', 'mb-3' ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.email.value),expression:"contactForm.email.value"}],staticClass:"form-control",attrs:{"name":"email","type":"email","required":"required","id":"email","data-rule":"email","placeholder":"Correo electrónico *","disabled":_vm.sent || _vm.sending},domProps:{"value":(_vm.contactForm.email.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactForm.email, "value", $event.target.value)}}}),(!_vm.contactForm.email.status)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido y debe tener el formato correcto. ")]):_vm._e()]),_c('div',{class:[
          _vm.nameRoute == 'DevelopmentSections' ? 'col-md-4' : 'col-md-12', 'mb-3'
        ]},[_c('vue-tel-input',_vm._b({staticClass:"form-control",attrs:{"disabled":_vm.sent || _vm.sending,"required":"required","placeholder":"Teléfono *"},model:{value:(_vm.contactForm.phone.value),callback:function ($$v) {_vm.$set(_vm.contactForm.phone, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactForm.phone.value"}},'vue-tel-input',_vm.bindTelProps,false)),(!_vm.contactForm.phone.status)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido. ")]):_vm._e()],1),(_vm.nameRoute != 'DevelopmentSections')?_c('div',{class:[
          _vm.nameRoute == 'DevelopmentSections' ? 'col-md-6' : 'col-md-12',
          'mb-5' ]},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contactForm.message.value),expression:"contactForm.message.value"}],staticClass:"form-control",attrs:{"name":"message","id":"message","cols":"30","rows":"5","required":"required","data-rule":"required","placeholder":"Mensaje *","disabled":_vm.sent || _vm.sending},domProps:{"value":(_vm.contactForm.message.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contactForm.message, "value", $event.target.value)}}}),(!_vm.contactForm.message.status)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"col-md-12"},[(!_vm.sending)?_c('button',{staticClass:"btn btn-primary py-3 px-5 mb-2",attrs:{"disabled":_vm.sent,"type":"submit"}},[_vm._v(" Enviar ")]):_c('div',[_c('Preloader')],1)])]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_vm._v(" Este sitio está protegido por reCAPTCHA y Google "),_c('a',{attrs:{"href":"https://policies.google.com/privacy"}},[_vm._v("Política de privacidad")]),_vm._v(" y Se aplican "),_c('a',{attrs:{"href":"https://policies.google.com/terms"}},[_vm._v("Términos de servicio")]),_vm._v(" . ")])}]

export { render, staticRenderFns }