<template>

  <form action="#" class="search-property">
    <div class="row justify-content-center">
        <div class="col-12 align-items-end">
          <div class="form-group">
                  <!-- <label for="#">¿Qué estás buscando?</label> -->
              <div class="form-field">
                  
                  <input v-model="search.keyword" type="text" class="form-control color-search" placeholder="¿Qué éstas buscando?">
                  <div class="icon"><span class="icon-pencil"></span></div>
              </div>
          </div>
        </div>
        <div class="col-12 align-items-end">
          <div class="form-group">
            <!-- <label for="#">Categoria</label> -->
            <div class="form-field">
              <div class="select-wrap">
                <select v-model="search.category" name="" id="" class="form-control">
                <option value="">Categorias</option>
                <option value="">Celulares</option>
                <option value="">Casas</option>
                <option value="">Autos</option>
                <option value="">Ropa</option>
                <option value="">Bicicletas</option>
                </select>
                <div class="icon"><i class="fas fa-sort-down"></i></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md align-items-end">
            <div class="form-group">
                <label for="#">Property Type</label>
                <div class="form-field">
                <div class="select-wrap">
            <div class="icon"><span class="ion-ios-arrow-down"></span></div>
            <select name="" id="" class="form-control">
            <option value="">Type</option>
            <option value="">Commercial</option>
            <option value="">- Office</option>
            <option value="">Residential</option>
            <option value="">Villa</option>
            <option value="">Condominium</option>
            <option value="">Apartment</option>
            </select>
        </div>
            </div>
        </div>
        </div> -->

        <div class="col-md-6 align-self-end">
            <div class="form-group">
                <div class="form-field">
                   <button type="submit" value="Search" class="form-control btn btn-primary" @click="goToResults()">
                <i class="fas fa-search"></i>
                BUSCAR
            </button>
            </div>
        </div>
        </div>
    </div>
</form>
</template>
<script>
export default {
  data() {
    return {
      search: {
        state: "",
        keyword: "",
        city: "",
        category: "",
        type: ""
      }
    };
  },
  methods: {
    goToResults() {
      this.$router.push({
        name: "Category",
        params: this.search
      });
    },
  },
};
</script>
<style scoped>
.color-search{
    color: #000 !important;
}

</style>