<template>
<div class="container fluid states">
  <div class="row mt-4 text-center">
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
       <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%"> 
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
       <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
    <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
  <div class="imgstate col-sm-6 col-md-4 mb-3">
      <a href="/lista"> 
        <img src="../../../public/images/examples/guanajuato.jpg" width="100%" height="100%">
        <h3 class="image-info"> GUANAJUATO </h3>
      </a> 
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
        search: {
            state: "",
            keyword: "",
            city: "",
            category: "",
            type: ""
        }
        };
    },
   computed: {
     ...mapGetters({
       properties: "_getStates"
     }),
   },
   created() {
       this.$store.dispatch("getStates");
   }
};

</script>

<style scoped>
.imgstate{
  overflow: hidden;
}
  .imgstate:hover img{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1.1, 1.1);
  }
  .imgstate:hover h3{
    margin: 0 36px;
  }
  .states img{
    border-radius: 8px; 
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: all 0.4s ease-out;
  }
  .image-info {
    position: absolute;
    z-index: 2;
    top: calc(150px - 20px);
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 10px 50px;
    margin: 0 50px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    font-weight: 300;
    letter-spacing: 0.2px;
    transition: all 0.3s ease-out;
  }
</style>