<template>
  <div
    class="
      col-md-12
      d-flex
      align-self-stretch
      wow
      slideInUp
      cardgeneral
      property-hor
      mb-3
      pb-3
      pt-3
    "
  >
    <div class="card">
      <a @click="goToProperty()">
        <div class="row no-gutters">
          <div class="col-md-4">
            <img
              width="100%"
              src="../../../public/images/examples/house.jpg"
              class="card-img"
              alt="..."
            />
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">{{ item.name }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">
                <i class="icon-location"></i> {{ item.city }} , {{ item.state }}
              </h6>
              <!-- <p>
                <i class="icon-location"></i>Av. Bonampak Mz. 10 L 5 Calle
                Mar
              </p> -->
              <h4>$ {{ item.price }} {{ item.currency }}</h4>
              <div class="postcard-bar"></div>
              <!-- <p class="card-text">
                Casa en venta en Isla Dorada (propiedad de prueba), Lorem ipsum
                dolor sit amet,
              </p> -->
              <h6
                class="card-subtitle mb-2 text-muted"
                v-if="
                  item.m2c != 0 ||
                  item.mlot != 0 ||
                  item.bedrooms != 0 ||
                  item.bathrooms != 0
                "
              >
                <small class="text-muted">
                  <span
                    v-if="item.bedrooms > 0"
                    class="icon-bed-1 pr-2 pl-2 fa-2x">
                    </span>{{ item.bedrooms }} Rec.<span
                    v-if="item.bathrooms > 0"
                    class="icon-bathtub1 pr-2 pl-2 fa-2x"
                  ></span>{{ item.bathrooms }} baños
                  <span
                    v-if="item.m2c != 0"
                    class="icon-measure pr-2 pl-2 fa-2x">
                    </span>{{ item.m2c }}m<sup>2</sup>
                  <span
                    v-if="item.mlot != 0"
                    class="icon-house-design pr-2 pl-2 fa-2x">
                    </span>{{ item.mlot }}m<sup>2</sup>
                </small>
              </h6>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.name);
      let folio = this.item.productoid;
      let namePage="PropertyDetail";
      let params;

      if (this.nameRoute=='Agent') {
        namePage="PropertyAgent";
        params={
          folio,
          agentId:this.agent.folio
        }
      }else{
        params={
          folio,
          namePropFilt
        }
      }

      this.$router.push({
        name: namePage,
        params:params
      });
    },
  },
  created() {
    console.log("card", this.item);
  },
};
</script>

<style scoped>
.property-hor .card-img {
  object-fit: cover;
  object-position: center;
  height: 100%;
  display: block;
  border-top-left-radius: 15px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 15px;
}

.property-hor .card-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.property-hor span,
h4 {
  color: #00569d;
  font-weight: 500;
}
.property-hor i {
  color: #f2e115;
}
@media only screen and (max-width: 991px) {
  .property-hor .card-img {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
  }
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1.25rem;
  top: 0px;
  transition: 0.3s all ease;
}
.postcard-bar {
  width: 50px;
  height: 3px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f2e115;
  transition: width 0.2s ease;
}
.card:hover .postcard-bar {
  width: 100px;
}
.card:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  top: -6px;
}
.pricecard {
  font-weight: 600;
}
.cardgeneral {
  border-radius: 10px;
  overflow: hidden !important;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(1.25rem - 1px);
  border-top-right-radius: calc(1.25rem - 1px);
}
small {
  font-size: 60%;
}
.cardgeneral .fa-1x {
  font-size: 1.5em;
}
</style>
