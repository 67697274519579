import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
     API_URL: "https://www.clasificadoscontacto.com/dev/api/",
    // CONTACT_URL:
    //   "https://www.immosystem.com.mx/api/mailWeb/mailerContactExample.php",
     API_PARAMS: {
       method: "POST",
       headers: {
         "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
       },
       body: {},
     },
    SEARCH_PROPERTIES: '?site=aHoxd1ZFOUdhYlRmNC9zMWRSNmdpTm15VVptaWE4aUhCaysraEk1ZFhJND0=',
    ADD_USER: '?site=bERwdld0UGkwNU5iVmh4cDZzbU1BQT09',
    LOGIN_USER: '?site=a0VXNDI5SHhCSlljR1BXaThyL2kyQT09',
    GET_PROPERTYDETAIL: '?site=elBvMEtpVmt4Qjk1U3dhMXNENDZQZWpQSnJPNjBOM3FxL203d1VRQ2U2TT0=',
    GET_STATES: '?site=elBvMEtpVmt4Qjk1U3dhMXNENDZQZWpQSnJPNjBOM3FxL203d1VRQ2U2TT0=',
    // NEWS_VISITS: 0,
    // NEWS_CONTACTS: 0,
    // USER_IP: null,
    // CAPTCHA_KEY: "6Lf_1ZMUAAAAAC1iY_FzMJpgod5bIVl_2YYHLT9g",
    // ID_COMPANY: "4",
    URL_SITE: "https://www.clasificadoscontacto.com/dev/front",
    ACTUAL_PAGE: "",
    // INFO_COMPANY: null,
    ERROR_CODE: 0,
    // MATADATA: {
    //   company: 0,
    //   detail: 0,
    // },
    // PROPS_PER_PAGE: 9,
    // AGENTS_PER_PAGE: 8,
    propertyList: null,
    propertyDetail: null,
    statesList: null,
  },
  mutations: {
    setPropertyDetail(state,property){
      state.propertyDetail = property;
    },
    setPropertyList(state,properties){
      state.propertyList = properties;
    },
    setStatesList(state,states){
      state.statesList = states;
    },
    setError(state, code) {
      state.ERROR_CODE = code;
    },
    userRegister(state,storageuser){
      // aqui haras una accion si el registro fue exitoso
    },
    userLogin(state,storageuser){
      if(!localStorage.getItem('data')){
        console.log('no hay nada en el storage')
        localStorage.setItem('folio', storageuser.folio)
      }else{
        console.log('hay datos guardados');
        localStorage.setItem('folio', storageuser.folio)
      }
    }
  },
  actions: {
    propertyDetail({ commit, state },id){
      let config = state.API_PARAMS;
      let data = `folio=${id}`;

      config.body = data;
      if (!state.developmentsList) {
          fetch(state.API_URL + state.GET_PROPERTYDETAIL, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("setPropertyDetail", res.data);
                          commit("setError", res.status);
                      } else {
                        commit("setPropertyList", res.data);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
      }
    },
    searchProducts({ commit, state },forData){
      let config = state.API_PARAMS;
      let data = `state=${forData.state}&keyword=${forData.keyword}&city=${forData.city}&category=${forData.category}`;

      config.body = data;
      if (!state.developmentsList) {
          fetch(state.API_URL + state.SEARCH_PROPERTIES, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("setPropertyList", res.data);
                          commit("setError", res.status);
                      } else {
                        commit("setPropertyList", res.data);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
      }
    },
    searchProperties({ commit, state }){
      let config = state.API_PARAMS;
      let data = ``;

      config.body = data;
      if (!state.developmentsList) {
          fetch(state.API_URL + state.SEARCH_PROPERTIES, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("setPropertyList", res.data);
                          commit("setError", res.status);
                      } else {
                          commit("setError", 204);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
      }
    },
    getStates({ commit, state }){
      let config = state.API_PARAMS;
      let data = ``;

      config.body = data;
          fetch(state.API_URL + state.GET_STATES, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("setStatesList", res.data);
                          commit("setError", res.status);
                      } else {
                          commit("setError", 204);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
    },
    userRegister({ commit, state },userData){

      let config = state.API_PARAMS;
      let data = `RDx_nombre=${userData.RDx_nombre}&RDx_correo=${userData.RDx_correo}&RDx_contra=${userData.RDx_contra}&RDx_empresa=${userData.RDx_empresa}`;
      config.body = data;
      if (userData) {
          fetch(state.API_URL + state.ADD_USER, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("userRegister", res.data);
                          commit("setError", res.status);
                      } else {
                          commit("setError", 204);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
      }
    },
    userLogin({ commit, state },userData){

      let config = state.API_PARAMS;
      let data = `RDx_correo=${userData.RDx_correo}&RDx_contra=${userData.RDx_contra}`;
      config.body = data;
      if (userData) {
          fetch(state.API_URL + state.LOGIN_USER, config)
              .then((res) => res.json())
              .then((res) => {
                  if (res.status == 200) {
                      if (res.data.length != 0) {
                          commit("userLogin", res.data);
                          commit("setError", res.status);
                      } else {
                          commit("setError", 204);
                      }
                  } else {
                      commit("setError", res.status);
                  }
              });
      }
    }
  },
  getters: {
    _getPropertyDetail(state){
      return state.propertyDetail;
    },
    _getProperties(state){
      return state.propertyList;
    },
    _getStates(state){
      return state.statesList;
    }
  },
})
