<template>
  <div>

    <br />
    <!-- <NotResults v-if="error == 204" code="204" /> -->
    <div  class="container">
      <div class="mb-5">
        <template>
          <div class="row">
          <!-- <div
            class="col-md-4"
            v-for="(item, i) in results"
            :key="item.folio"
            :name="item.folio"
            :index="i"
          > -->
          <div
          class=
          col-lg-3
          col-md-12
          pt-4
          mt-2>
            <!-- <PropertyHorCard :item="item" /> -->
            <SearchFilters v-on:getData="onResult" />
          </div>
          <div
          class=
          col-lg-9
          col-md-12
          >
            <!-- <PropertyHorCard :item="item" /> -->
            <PropertyHorCard
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item"
            />
          </div>
        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchFilters from "@/components/search/SearchFilters.vue";
import Preloader from "@/components/layout/Preloader.vue";
import PropertyHorCard from "@/components/properties/PropertyHorCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
  data() {
    return {
      search: {
        state: "",
        keyword: "",
        city: "",
        category: "",
        type: ""
      }
    };
  },
  components: {
    SearchFilters,
    PropertyHorCard,
    Preloader,
    NotResults,
  },
     methods: {
      onResult(datos) {
       this.search = datos;
       this.$store.dispatch("searchProducts",this.search);
      }
   },
    computed: {
     ...mapGetters({
       properties: "_getProperties"
     }),
   },
    created() {
      if(this.$route.params != undefined){
        this.search = this.$route.params;
        this.$store.dispatch("searchProperties", this.search);
      }else{
        this.$store.dispatch("searchProperties");
      }
   }
  // data() {
    // return {
    //   codeShareProperties: 0,
    //   revRoute: null,
    //   developments: false,
    // };
  // },
  // computed: {
    //...mapGetters({
      // results: "_getResults",
      // error: "_getError",
   // }),
    // fromDevs() {
    //   if (this.$route.name == "ResultsDevs") {
    //     return true;
    //   }
    // },
  //},
  // beforeRouteEnter(to, from, next) {
  //   next((self) => {
  //     self.prevRoute = from;
  //     if (!self.prevRoute.name) {
  //       self.$router.push({ name: "Home" });
  //     }
  //   });
  // },
};
</script>

<style scoped>
  
</style>