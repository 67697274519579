<template>
  <div
    class="
      col-md-3
      d-flex
      align-self-stretch
      ftco-animate
      cardgeneral
      mb-3
      pb-3
      pt-3
    "
  >
    <div class="card">
      <a @click="goToProperty()">
        <img
          id="propImg"

          v-bind:src="item.image"
          :alt="item.alt"
          class="class-img-top"
        />
        <div class="card-body glyphs">
          <h5 class="card-title pricecard">
            $ {{ item.price | format }} {{ item.currency }}
          </h5>
          <div class="postcard-bar"></div>
          <p class="card-text">{{ item.name }}</p>
          <p class="card-text text-center"
          v-if="
            item.m2c != 0 ||
            item.mlot != 0 ||
            item.bedrooms != 0 ||
            item.bathrooms != 0
          ">
            <small class="text-muted">
              <i  v-if="item.m2c != 0" class="fas fa-ruler-combined fa-1x pl-2 pr-1"></i>{{ item.m2c }} m<sup>2</sup>
              <i  v-if="item.bedrooms > 0" class="fa fa-bed fa-1x pl-2 pr-1"></i>{{ item.bedrooms }} Rec.
              <i  v-if="item.bathrooms > 0" class="fa fa-bath fa-1x pl-2 pr-1"></i>{{ item.bathrooms }} baños
              </small>
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
    filters: {
    truncate: function(str) {
      return str.substring(0, 125) + "...";
    },
    areaFormat:function(value) {
      return numeral(value).format("0,0");
    },
    format: function(value) {
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      
      // Precio formateado value.substr(currentPrice)
      let formatPrice=value.substr(0,decimalPrice)+" ";
      if(currentPriceM!='-1'){
        formatPrice+= value.substr(currentPriceM)
      }else{
        formatPrice+= value.substr(currentPriceU)
      }
     
      return formatPrice;
      //return numeral(value).format("0,0");
    }
  },
  methods: {
    goToProperty() {

      let namePropFilt = services.normalize(this.item.name);
      let folio = this.item.productoid;
      let namePage="PropertyDetail";
      let params;

      if (this.nameRoute=='Agent') {
        namePage="PropertyAgent";
        params={
          folio,
          agentId:this.agent.folio
        }
      }else{
        params={
          folio,
          namePropFilt
        }
      }

      this.$router.push({
        name: namePage,
        params:params
      });
    },
  },
  created() {
    console.log("card", this.item);
  },
};
</script>

<style scoped>
#propImg {
  height: 250px;
  width: 350px;
  cursor: pointer;
}
@media (max-width: 767px) {
  #contain_tarjet_property {
    width: 65%;
  }
  #propImg {
    height: auto;
    width: 100%;
  }
}
.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 1.25rem;
  top: 0px;
  transition: 0.3s all ease;
}
.postcard-bar {
  width: 50px;
  height: 3px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #f2e115;
  transition: width 0.2s ease;
}
.card:hover .postcard-bar {
  width: 100px;
}
.card:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  top: -6px;
}
.pricecard {
  font-weight: 600;
}
.cardgeneral {
  border-radius: 10px;
  overflow: hidden !important;
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(1.25rem - 1px);
  border-top-right-radius: calc(1.25rem - 1px);
}
small {
  font-size: 60%;
}
.cardgeneral .fa-1x {
  font-size: 1.5em;
}
</style>
