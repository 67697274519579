<template>
  <section class="height-70 bg-gradient pt-2 pb-2">
    <img
      src="../../../public/images/examples/background-yellow.jpg"
      alt="Image"
      class="bg-image opacity-60"
    />
    <div class="hero-wrap">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-6 ftco-animate text-center">
            <router-link to="/">
              <img src="../../../public/clasificadoslogooff.png" />
            </router-link>
            <div class="d-block d-sm-block d-md-none">
              <img src="../../../public/images/examples/sideimage.png" alt="" />
            </div>
            <section class="ftco-search mt-2 mb-2">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-12 search-wrap">
                    <SearchForm />
                  </div>
                </div>
              </div>
            </section>
            <section class="ftco-section">
              <div class="container">
                <Icons />
              </div>
            </section>
          </div>
          <div class="col-md-6 text-right d-none d-sm-none d-md-block ftco-animated fadeInRight">
            <img src="../../../public/images/examples/sideimage.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// export default {
//   props: {
//     image:{
//       type: String,
//       require: true
//     },
//     pageTitle:{ type:String, required:true}
//   },
// };
import SearchForm from "@/components/search/SearchForm.vue";
import Icons from "@/components/layout/Icons.vue";
export default {
  components: {
    SearchForm,
    Icons,
  },
};
</script>

<style>
img {
  width: 80%;
}
/* .bg-gradient {
    background: linear-gradient(90deg, #0099FF 0%, #f2ba10 100%);
} */
.height-70 {
  min-height: 70vh;
}
img.bg-image {
  object-fit: cover;
}
.opacity-60 {
  opacity: 0.4;
}
img {
  max-width: 100%;
}
.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.bg-image:not([class*="absolute"]) {
  position: absolute;
}
::before,
::after {
  box-sizing: border-box;
}
[class*="height-"]:not(.align-items-initial) {
  align-items: center;
}
[class*="height-"] {
  display: flex;
}
section {
  position: relative;
}
section {
  padding: 4.5rem 0;
}
</style>