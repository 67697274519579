<template>
  <main>
    <Preloader v-if="!property"/>
    <div v-else>
      <!-- GENERAL CONTAINER -->
      <section class="ftco-section detail-section bg-light pb-5">
        <div class="container">
          <DetailCarousel :items="property.images"/>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-5">
              <div class="listing_details_top_left">
                <div class="listing_details_top_title">
                  <h1>{{property.productName}}</h1>
                  <span> <i class="icon-location"></i> {{property.city}} , {{property.state}} </span>
                  <!-- <p>
                    <i class="icon-location"></i>Av. Bonampak Mz. 10 L 5 Calle
                    Mar
                  </p> -->
                  <h4>${{property.price}} {{property.currency}}</h4>
                </div>
              </div>
            </div>
            <div class="col-md-7">
              <div class="listing_details_top_right clearfix">
                <div class="listing_details_top_product_list_box">
                  <ul class="listing_details_top_product_list list-unstyled">
                    <li>
                      <div class="icon_box">
                        <span class="icon-hashtag"></span>
                      </div>
                      <div class="text_box">
                        <h5>Folio</h5>
                        <p>{{property.folio}}</p>
                      </div>
                    </li>
                    <li v-if="property.bedrooms > 0" >
                      <div class="icon_box">
                        <span class="icon-bed-1"></span>
                      </div>
                      <div class="text_box">
                        <h5>{{property.bedrooms}}</h5>
                        <p>recamaras</p>
                      </div>
                    </li>
                    <li v-if="property.bathrooms > 0">
                      <div class="icon_box">
                        <span class="icon-bathtub1"></span>
                      </div>
                      <div class="text_box">
                        <h5>{{property-bathrooms}}</h5>
                        <p>baños</p>
                      </div>
                    </li>
                    <li v-if="property.m2c > 0">
                      <div class="icon_box">
                        <span class="icon-measure"></span>
                      </div>
                      <div class="text_box">
                        <h5>{{property.m2c}}</h5>
                        <p>m<sup>2</sup></p>
                      </div>
                    </li>
                    <li v-if="property.mlot > 0">
                      <div class="icon_box">
                        <span class="icon-measure"></span>
                      </div>
                      <div class="text_box">
                        <h5>{{property.mlot}}</h5>
                        <p>m<sup>2</sup></p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <!-- <div class="col-md-6">
                    <div class="text_box2">
                      <h5>Referencia</h5>
                      <p>A un costado de plaza Marina Town Center</p>
                    </div>
                  </div> -->
                  <div class="col-md-6 mt-3">
                    <div class="share-property text-center">
                      <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" class="pl-3 pr-3">
                        <span class="icon-facebook"></span>
                      </a>
                      <a :href="`https://twitter.com/home?status=${url}`" target="_blank" class="pl-3 pr-3">
                        <span class="icon-twitter"></span>
                      </a>
                      <a :href="`https://api.whatsapp.com/send?text=${$t('pages.property_detail.share_whats_message_public')}: ${url}`" target="_blank" class="pl-3 pr-3">
                        <span class="icon-whatsapp"></span>
                      </a>
                      <div class="text-center mb-3">
                        <p>COMPARTE</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="property-info pt-3 pb-3">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#description"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Descripción</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#amenity"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Amenidades</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#reality"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    >Realidad Virtual</a
                  >
                </li>
              </ul>
              <div class="tab-content mt-3 mb-3" id="myTabContent">
                <!-- DESCRIPCION/UBICACION/VIDEO -->
                <div
                  class="tab-pane fade show active
                      fadeInUp
                      ftco-animated"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab"
                >
                  <div class="col-md-12 mb-3 text-justify">
                    {{property.description}} <br> {{property.descriptionlong}}
                  </div>
                  <div>
                    <div
                      class="
                        col-md-12
                        ftco-animate
                        fadeInUp
                        ftco-animated
                        pt-3
                      "
                    >
                     <h2>UBICACIÓN</h2>
                    </div>
                    <div v-if="property.latitude || property.longitude" class="col-md-12 mb-3 pt-2">
                      <div class="col-12">
                        
                        <GoogleMap :mapLat="property.latitude" :mapLng="property.longitude" />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- AMENIDADES -->
                <div
                  class="tab-pane fade"
                  id="amenity"
                  role="tabpanel"
                  aria-labelledby="amenity-tab"
                >
                  <div
                    class="
                      amenities
                      ftco-animate
                      mb-5
                      mt-4
                      fadeInUp
                      ftco-animated
                    "
                  >
                    <h2 class="text-center">AMENIDADES</h2>
                    <h2>Interiores</h2>
                    <div class="container-fluid">
                    <ul>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-dining-room"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Comedor</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-office"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Estudio</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-air-conditioner-1"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Aire acondicionado</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-kitchen"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Cocina</p>
                        </div>
                      </li>
                    </ul>
                    </div>
                    <h2>Exteriores</h2>
                    <div class="container-fluid">
                    <ul>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-garden"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Jardín</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-garage"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Estacionamiento</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-elevator"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Elevador</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-terraza"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Terraza</p>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-bar"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Bar</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-gym"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Gimnasio</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-pool"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Alberca</p>
                        </div>
                      </li>
                      <li>
                      </li>
                    </ul>
                    </div>
                    <h2>Servicios</h2>
                    <div class="container-fluid">
                    <ul>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-light"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Luz</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-water"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Agua</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-gas"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Gas</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-alarm"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Sistema de alarma</p>
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-energia-verde"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Energia <br> sustentable/renovable</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-wifi"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Wifi</p>
                        </div>
                      </li>
                      <li>
                        <div class="listings_details_icon">
                          <span class="icon-desague"></span>
                        </div>
                        <div class="listings_details_content">
                          <p>Desague</p>
                        </div>
                      </li>
                      <li>
                      </li>
                    </ul>
                    </div>
                  </div>
                </div>
                <!-- REALIDAD V -->
                <div
                  class="tab-pane fade                       
                      fadeInUp
                      ftco-animated"
                  id="reality"
                  role="tabpanel"
                  aria-labelledby="reality-tab"
                >
                  ...
                </div>
              </div>
            </div>
            <!-- FORMULARIO AGENTE -->
            <div class="col-md-4 bg-softcc">
              <div class="ftco-section contact-section">
                <div class="ml-1 mr-1 text-center">
                  <img
                    class="img img-agent"
                    width="50%"
                    src="https://thumbs.dreamstime.com/b/default-avatar-profile-trendy-style-social-media-user-icon-187599373.jpg"
                  />
                  <p class="ml-2 pt-3 font-weight-bold text-left mb-1">
                    SARA NAOMI
                  </p>
                  <p class="mb-0 pt-2 ml-2 text-left">
                    <b class="texto2"><i class="icon-phone"></i> </b>
                    <a
                      class="contact-links"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Contactar en WhatsApp"
                      target="_blank"
                    >
                      9980000000
                    </a>
                  </p>
                  <p class="mb-1 ml-2 text-left">
                    <b class="texto2"
                      ><i class="icon-mail-envelope-closed"></i>
                    </b>
                    <a class="contact-links"> agente@example.com </a>
                  </p>
                </div>
                <!-- Contact Form -->
                <div class="rounded">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import DetailCarousel from "@/components/carousel/DetailCarousel.vue"
import { mapGetters } from "vuex";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import Preloader from "@/components/layout/Preloader.vue";
export default {
  name: "app",
  components: {
    ContactForm,
    DetailCarousel,
    GoogleMap,
    Preloader
  },
  data() {
    return {
      contact: true,
      url: "",
    };
  },
  computed: {
    ...mapGetters({
      property: "_getPropertyDetail"
    }),
  },
  created() {
    this.folio = this.$route.params.folio;
    if(this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("propertyDetail",data.folio);
    console.log('wefwef ', this.property)
    console.log('propertyDetail', this.property)
    console.log('agentId', this.agentId)
  }
};
</script>
<style scoped>
.nav-tabs .nav-link {
    border-color: #e9ecef #e9ecef #dee2e6;
}
.amenities ul {
  display: flex;
  list-style: none;
}

.amenities li {
  flex: auto;
}

.amenities span {
  color: #00569d;
  font-size: 35px;
}
.img-agent {
  border-radius: 50% !important;
}
.listings_details_content p {
  font-size: 13px;
}
.property-info img {
  width: 30% !important;
}
.property-info .bg-softcc {
  border-radius: 15px;
}
.listings_details_features_list li {
  position: relative;
  display: block;
  padding-left: 40px;
}
.listings_details_features_list li .listings_details_icon {
  position: absolute;
  top: 0;
  left: 0;
}
.texto2 {
  color: #00569d;
}
.detail-section h1 {
  font-size: 30px !important;
  font-weight: 600 !important;
}
.detail-section i {
  color: #f2e21e;
}
.listing_details_top_title h4 {
  font-size: 24px;
  color: #00569d;
  font-weight: 700;
  line-height: 10px;
}
.detail-section h5 {
  font-size: 18px !important;
}
.detail-section p {
  font-size: 15px;
  color: #717580;
}
.listing_details_top_product_list_box::before {
  position: absolute;
  top: 16px;
  left: -30px;
  bottom: 16px;
  content: "";
  width: 1px;
  background: #e0e3eb;
}
.listing_details_top_product_list {
  display: flex !important;
  align-items: center;
}
.listing_details_top_product_list li {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .listing_details_top_product_list li {
    flex-direction: column;
    text-align: center;
  }
  .listing_details_top_product_list li .text_box p {
    font-size: 12px;
  }
  .listing_details_top_product_list li .text_box h5 {
    font-size: 14px;
  }
  .amenities ul {
    display: inline-block;
  }

  .amenities li {
    flex: inherit;
  }
}

.listing_details_top_product_list li .icon_box {
  height: 54px;
  width: 54px;
  border: 1px solid #e0e3eb;
  border-radius: 50%;
  color: #00569d;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listing_details_top_product_list li .text_box {
  margin-left: 10px;
  line-height: 0.5 !important;
}
.listing_details_top_product_list li .text_box1 {
  margin-left: 10px;
  line-height: 1.5;
}
.listing_details_top_product_list li + li {
  margin-left: 30px;
}
.share-property span {
  font-size: 23px;
}
.property-info .heading-section h2 {
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}
</style>
