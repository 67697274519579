import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from "../views/Home/";
import Contact from "../views/Contact/";
import SignUp from "../views/SignUp/";
import Login from "../views/Login/";
import PropertyDetail from "../views/PropertyDetail/";
import CarDetail from "../views/CarDetail/";
import Results from "../views/Results/";
import StateList from "../views/StateList/";
import Category from "../views/Category/";
import NotFound from "../views/Error/";

Vue.use(VueRouter);

const routes=[
  {
    path: "/",
    name: "Home",
    component: Home,
    meta:{
      slide:true,
      hideBanner:true,
      hideSearch: true,
    },
  },
  {
    path: "/contacto",
    name: "Contact",
    component: Contact,
    meta: {
        breadcrumb:'Contacto',
        pageTitle: 'routes.contact.page_title',
    }
  },
  {
    path: "/registro",
    name: "SignUp",
    component: SignUp,
    meta: {
        pageTitle: 'routes.SignUp.page_title',
       hideBanner:true,
    }
  },
  {
    path: "/sesion",
    name: "Login",
    component: Login,
    meta: {
        pageTitle: 'routes.Login.page_title',
        hideBanner:true,
    }
  },
  {
    path: '/propiedad/:folio/:namePropFilt',
    name: 'PropertyDetail',
    component: PropertyDetail,
    meta:{
      hideBanner:true,
    },
  },
  {
    path: '/automovil',
    name: 'CarDetail',
    component: CarDetail,
    meta:{
      pageTitle:'routes.property.page_title',
      hideBanner:true,
      hideSearch: true,
    },
  },
  {
    path: '/lista',
    name: 'StateList',
    component: StateList,
    meta: {
      // pageTitle: 'routes.StateList.page_title',
      pageTitle: 'ESTADO',
      hideBanner: false,
    }
  },
  {
    path: '/categoria',
    name: 'Category',
    component: Category,
    meta: {
        pageTitle: 'routes.Category.page_title',
        hideBanner: true,
    }
  },
  {
    path: '/busqueda',
    name: 'Results',
    component: Results,
    meta: {
        pageTitle: 'routes.search_results.page_title',
    }
  },
  {
    path: "/:catchAll(.*)",
        component: NotFound,
        name: "Error",
        meta: {
            pageTitle: 'routes.error.page_title',
            hideSearch: true,
        }
  }, 
];

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  document.documentElement.scrollTop = 0;
});

// This callback runs before every route change, including on page load.
/*router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});*/



export default router
