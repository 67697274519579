<template>
<div class="col-md-3 d-flex align-self-stretch ftco-animate cardgeneral mb-3">
  <div class="card">
    <a @click="goToCar()">
    <img class="card-img-top" src="../../../public/images/examples/car.jpg" alt="Card image cap">
      <div class="card-body glyphs">
        <h5 class="card-title pricecard"> $130,100 MX </h5>
        <div class="postcard-bar"></div>
        <p class="card-text">AUTO RAYO MCQUEEN</p>
      </div>
    </a>
  </div>
</div>
</template>

<script>
export default {
    methods: {
        goToCar() {
        this.$router.push({
            name: "PropertyCar",
        });
        },
    },
};
</script>

<style scoped>
.card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 1.25rem;
  }
  .postcard-bar {
    width: 50px;
    height: 3px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #f2e115;
    transition: width 0.2s ease;
  }
  .card:hover .postcard-bar {
    width: 100px;
  }
  .card:hover{
   box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
  .pricecard{
    font-weight: 600;
  }
  .cardgeneral {
    border-radius: 10px;
    overflow: hidden !important;
  }
  .card-img-top {
      width: 100%;
      border-top-left-radius: calc(1.25rem - 1px);
      border-top-right-radius: calc(1.25rem - 1px);
  }
  small{
    font-size: 60%;
  }
  .cardgeneral .fa-1x{
    font-size: 1.5em;
  }
</style>