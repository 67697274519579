<template>
  <section class="bannr-section">
    <div class="hero-wrap" style="background-image: url('./images/examples/bannr2.jpg');">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9 ftco-animate text-center">
            <p  class="breadcrumbs"> 
              <!-- <span class="mr-2"><a href="/"> {{ $t("routes.home.breadcrumb") }}</a></span
              >/ <span> {{(`${pageTitle}`)}}</span> </p> -->
            
          <h1 class="mb-3 bread font-weight-bold">{{$t(`${pageTitle}`)}}</h1>
            <!-- <h1 class="mb-3 bread font-weight-bold">REGISTRO</h1>  -->
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff"  d="M0,128L720,288L1440,256L1440,320L720,320L0,320Z"></path></svg>
  
 </section>
</template>

<script>
export default {
  props: {
    image:{
      type: String,
      require: true
    },
    pageTitle:{ type:String, required:true}
  },
};
</script>

<style>
.wave{
  position: absolute; 
  bottom: 0;
   left: 0;
}
/* .bannr-section{
  width: 100%;
  height: 70vh;
  padding: 5rem;
  background: linear-gradient(180deg, #008aff, #ff137d), url(/images/examples/bannr2.jpg);
  background-size: cover;
  /* background-blend-mode: screen; */

</style>