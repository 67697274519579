<template>
  <div>
              
    <section class="ftco-section signup-section pb-5">
      <div class="container cont">
        <div class="row">
          <div class="col-md-7 mb-3">
            <div class="registration-form text-center">
              <form
              id="registro"
              v-on:submit.prevent="createUser();"
              method="post" >
                <div class="mb-2 mt-2 text-center">
                  <h1 class="font-weight-bold">REGÍSTRATE EN</h1>
                  <router-link to="/">
                    <img src="../../../public/clasificadoslogo.png" />
                  </router-link>
                </div>
                <!-- <pre> {{$v.$invalid}} </pre> -->
                <div class="form-group">
                    <input v-model="newUser.RDx_nombre" type="text" class="form-control item pl-4 mb-3 mt-3" @blur="$v.newUser.RDx_nombre.$touch()" placeholder="Nombre">
                    <small><span v-if="!$v.newUser.RDx_nombre.required && $v.newUser.RDx_nombre.$dirty" class="text-danger">El nombre es requerido </span></small>
                    <small><span v-if="!$v.newUser.RDx_nombre.alpha && $v.newUser.RDx_nombre.$dirty" class="text-danger">El nombre es requerido </span></small>
                </div>
                <div class="form-group">
                  <input v-model="newUser.RDx_correo" type="text" class="form-control item pl-4 mb-3 mt-3" id="email" @blur="$v.newUser.RDx_correo.$touch()" placeholder="Correo electrónico">
                  <small><span v-if="(!$v.newUser.RDx_correo.required || !$v.newUser.RDx_correo.email) && $v.newUser.RDx_correo.$dirty" class="text-danger">Correo eletrónico es requerido </span></small>
                </div>
                <div class="form-group">
                    <input v-model="newUser.RDx_contra" type="password" class="form-control item pl-4 mb-3 mt-3" id="password" @blur="$v.newUser.RDx_contra.$touch()" placeholder="Contraseña">
                    <small><span v-if="!$v.newUser.RDx_contra.required && $v.newUser.RDx_contra.$dirty" class="text-danger">Contraseña es requerida </span></small>
                </div>
                <div class="form-group">  
                    <input v-model="newUser.RDx_empresa" type="text" class="form-control item pl-4 mb-3 mt-3" id="businessname" @blur="$v.newUser.RDx_empresa.$touch()" placeholder="Nombre del negocio">
                  <small><span v-if="!$v.newUser.RDx_empresa.required && $v.newUser.RDx_empresa.$dirty" class="text-danger">Nombre de la empresa es requerido </span></small>
                </div>
                <div class="form-group">
                    <button type="submit" class="form-control btn btn-primary font-weight-bold" @click="createUser()" style="font-family:'Poppins;">CREAR MI CUENTA</button>
                </div>
                <a href="/sesion">¿Ya tienes cuenta?, <span> Inicia sesión </span> </a>
              </form>
         </div>
        </div>
         <div class="col-md-5 mb-3 align-self-center d-none d-sm-none d-md-block">
           
           <img src="../../../public/images/examples/girl-account.png" alt="sing up image">
                        
            </div>
      </div>
      </div>
    </section>
  </div>
    
</template>

<script>
import { required, alpha, email } from 'vuelidate/lib/validators'
export default {
  name: "app",
  components: {
    //Banner,
    // SearchForm,
    // ContactForm,
  },
  data() {
    return {
      contact: true,
      errors: [],
      newUser: {
        RDx_nombre: '',
        RDx_correo: '',
        RDx_contra: '',
        SDx_empresa: '',
      }
    };
  },
  validations:{
    newUser:{
      RDx_nombre: {
          required,
          alpha
      },
      RDx_correo: {
          required,
          email
      },
      RDx_contra: {
          required
      },
      RDx_empresa: {
          required
      },
    }
  },
  methods: {
    createUser(){
      this.sendData = true;
      var validate = false;

      // this.$v.$touch();
      if(!this.$v.$invalid){  
        // alert("registrando");
        validate = true;
      } else{
        this.$v.$touch();
      }
      this.newUser.RDx_contra = window.btoa(unescape(encodeURIComponent( this.newUser.RDx_contra )));
      validate = true;
      if(validate){
        this.$store.dispatch("userRegister", this.newUser);
      }

      console.log('ejecuto el metodo');
      // alert(this.newUser.RDx_nombre)
    }
  }
  // computed: {
  //   ...mapGetters({
  //     info: "_getInfoCompany",
  //   }),
  // },
};
</script>

<style >
.invalid-feedback{
  display: block;
}
.signup-section{
  background-color: #f8f8f8 !important;
}
.signup-section span{
  color: #00569d;
}
.signup-section a:hover{
  color: inherit;
}
.signup-section img{
  width: 100% !important;
}
.registration-form{
	padding: 30px 0;
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
.registration-form form{
    background-color: #fff;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-radius: 15px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075); */
}
@media (max-width: 576px) {
    .registration-form form{
        padding: 50px 20px;
    }
}
.registration-form .item{
	border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  border: inherit;
}

/* .registration-form .create-account{
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #5791ff;
    border: none;
    color: white;
    margin-top: 20px;
} */
.cont{
  max-width: 1100px !important;
  background: #fff;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  border-radius: 20px;
}


</style>
