<template>
  <form action="#" class="search-property ">
    <div class="row justify-content-center filter-search">
      <div class="col-12 align-items-end">
        <h2 class="text-center font-weight-bold mb-3">FILTROS</h2>
        <div class="postcard-bar"></div>
        <div class="form-group">
          <div class="form-field">
            <input
              type="text"
              class="form-control color-search"
              placeholder="¿Qué éstas buscando?"
            />
            <div class="icon"><i class="fas fa-search"></i></div>
          </div>
        </div>
      </div>
      <div  class="col-12 align-items-end " id="subcategories" >
        <div class="form-group">
          <!-- <label for="#">Categoria</label> -->
          <div class="form-field">
            <div class="select-wrap">
              <select v-model="search.category" name="" id="" class="form-control">
                <option value="1">Subcategorías</option>
                <option value="2">Casas</option>
                <option value="3">Departamentos en renta</option>
                <option value="4">Departamentos en venta</option>
                <option value="5">Hoteles</option>
                <option value="6">Terrenos</option>
              </select>
              <div class="icon"><i class="fas fa-list-ul"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 align-items-end">
        <div class="form-group">
          <!-- <label for="#">Categoria</label> -->
          <div class="form-field">
            <div class="select-wrap">
              <select v-model="search.state" name="" id="" class="form-control">
                <option value="1">Estado</option>
                <option value="2">Guanajuato</option>
                <option value="3">Querétaro</option>
                <option value="4">Morelia</option>
                <option value="5">San Luis Potosí</option>
              </select>
              <div class="icon"><i class="fas fa-map-marker-alt"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 align-items-end">
        <div class="form-group">
          <!-- <label for="#">Categoria</label> -->
          <div class="form-field">
            <div class="select-wrap">
              <input
              v-model="search.city"
                type="text"
                class="form-control color-search"
                placeholder="ciudad"
              />
              <div class="icon"><i class="fas fa-search-location"></i></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-12 align-items-end">
        <div class="form-group">
          <label for="#">Categoria</label> -->
          <!-- <div class="form-field"> -->
            <!-- <div class="select-wrap">
              <select v-model="search.category" name="" id="category" class="form-control">
                <option value="1">Categorias</option>
                <option value="2">Bienes Raíces</option>
                <option value="3">Electrónica</option>
                <option value="4">Vehículos</option>
              </select>
              <div class="icon"><i class="fas fa-list-ul"></i></div>
            </div>
          </div>
        </div>
      </div>  -->

      <div class="col-md-6 align-self-end">
        <div class="form-group">
          <div class="form-field">
            <button
              type="submit"
              value="Search"
              class="form-control btn btn-primary"
              @click="goToResults()"
            >
              <i class="fas fa-search"></i>
              BUSCAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
export default {
    data() {
    return {
      search: {
        state: "",
        keyword: "",
        city: "",
        category: "",
        type: ""
      }
    };
  },
  methods: {
    goToResults() {
      this.buscarDatos(this.search);
    },
    async buscarDatos(parametros) {
      this.$emit('getData', parametros);
    }
  },
  created() {
       this.$store.dispatch("searchProperties");
   }
};


</script>

<style scoped>
.color-search {
  color: #000 !important;
}
.filter-search {
  background-color: #f2e21e6b;
  border-radius: 15px;
}
.postcard-bar {
  width: 50px;
  height: 3px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #00569d;
  transition: width 0.2s ease;
}
form:hover .postcard-bar {
  width: 100px;
}
</style>